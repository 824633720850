import excursion1 from '../img/excursion_1.jpg';
import excursion2 from '../img/excursion_2.jpg';
import excursion3 from '../img/excursion_3.jpg';
import excursion4 from '../img/excursion_4.jpg';
import excursion5 from '../img/excursion_5.jpg';
import excursion6 from '../img/excursion_6.jpg';
import excursion7 from '../img/excursion_7.jpg';
import events1 from '../img/events_1.jpg';
import events2 from '../img/events_2.jpg';
import events3 from '../img/events_3.jpg';
import events4 from '../img/events_4.jpg';
import events5 from '../img/events_5.jpg';
import news1 from '../img/news_1.jpg';
import news2 from '../img/news_2.jpg';

const slidersData = [
    {
        header: 'Private themed journeys and events',
        slides: [
            {
                imageSrc: excursion1,
                title: 'Simply the Best',
                tags: [
                    'elite wineries',
                    'award-winning restaurants',
                    'pacific coast',
                    'redwood forests',
                    'hot springs'
                ],
                description: 'Discover Napa & Sonoma’s finest wineries, exclusive spots, and stunning landscapes. Experience top-tier restaurants and hidden gems on a personalized journey through Wine Country that will leave you in awe.'
            },
            {
                imageSrc: excursion2,
                title: 'Gastronomical Fireworks',
                tags: [
                    'chef dinners',
                    'gourmet pairings',
                    'acclaimed restaurants',
                    'farm-to-table'
                ],
                description: 'Experience Napa Valley’s renowned wines and farm-to-table cuisine on this exclusive tour. Enjoy premium tastings from top vineyards and indulge in gourmet dishes crafted to perfectly complement the wine.'
            },
            {
                imageSrc: excursion3,
                title: 'Wine and Nature',
                tags: [
                    'redwoods hiking',
                    'vineyard biking',
                    'safari',
                    'wine picnics',
                    'napa river kayaking',
                    'hot springs'
                ],
                description: 'Enjoy the perfect combination of outdoor adventure and fine wine in Napa Valley. This tour takes nature lovers through scenic trails, vibrant vineyards, and stunning landscapes, offering breathtaking views and premium wine tastings.'
            },
            {
                imageSrc: excursion4,
                title: 'Wine Enthusiasts',
                tags: [
                    'blending session',
                    'sommelier master-class',
                    'meet winemakers',
                    'harvest activities',
                    'barrel tastings'
                ],
                description: 'Immerse yourself in the art of winemaking with this hands-on experience in Napa Valley. Tour vineyards, meet winemakers, and craft your own blend, giving you a rare insider’s look into the world of premium wine.'
            },
            {
                imageSrc: excursion5,
                title: 'History Lovers',
                tags: [
                    'wine train',
                    'historic wineries',
                    'award-winning wines',
                    'meet owners',
                    'old cellars',
                    'amazing stories'
                ],
                description: 'Discover the rich history of Napa Valley on this engaging tour, tracing its multicultural roots and modern innovations that have elevated it to one of the world’s top wine regions.'
            },
            {
                imageSrc: excursion6,
                title: 'Wine and Wellness',
                tags: [
                    'spa',
                    'hot springs',
                    'redwoods yoga',
                    'hiking',
                    'organic wines',
                    'farmers markets',
                    'coastal journeys'
                ],
                description: 'Rejuvenate mind and body in Napa Valley with a tour that blends wine and wellness. Enjoy mindful wine tastings, vineyard walks, yoga, and luxurious spa treatments for a truly serene experience.'
            },
            {
                imageSrc: excursion7,
                title: 'Ultimate Luxury',
                tags: [
                    'high-end wineries',
                    'michelin restaurants',
                    'yacht cruise',
                    'hot-air balloon',
                    'helicopter tour'
                ],
                description: 'Indulge in ultimate luxury in Napa Valley. From private yacht cruises to hot air balloon rides and premium wine tastings, this tour offers an unforgettable fusion of opulence and natural beauty.'
            }
        ],
    },
    {
        header: 'Unique corporate events',
        slides: [
            {
                imageSrc: events1,
                title: 'Corporate Celebrations',
                description: 'Elevate your corporate event by having it in Napa Valley, offering a perfect blend of business and pleasure. Complement your event by our options to rent out our charming hotels and stunning winery venues. Enjoy interactive team-building activities and custom programs tailored to your objectives. Chef dinners and private tastings led by expert sommeliers will be a perfect finish for a productive day. Make your event elegant, educational, luxurious and overall unforgettable.'
            },
            {
                imageSrc: events2,
                title: 'Conferences workshops and seminars',
                description: 'Elevate your events with a luxury wine tour experience. Host conferences or workshops at stunning winery venues with customized activities and premium amenities for a truly inspiring atmosphere.'
            },
            {
                imageSrc: events3,
                title: 'Board Meetings',
                description: 'Imagine your next BoD meeting in a beautiful wine cellar with gourmet pairings. Enjoy private tastings and strategic sessions in elegant winery venues designed to enhance collaboration and high-level discussions.'
            },
            {
                imageSrc: events4,
                title: 'Investors, Clients and Partners Meetings ',
                description: 'Impress your guests with meticulously organized events in prestigious hotels and winery venues, including chef-prepared dinners, elite transportation, and custom activities that reflect your company’s commitment to excellence.'
            },
            {
                imageSrc: events5,
                title: 'Employee Incentives',
                description: 'Reward your team with unique incentive packages that combine bespoke wine experiences, blending sessions, and gourmet dining, designed to celebrate achievements and boost team spirit.'
            }
        ]
    },
    {
        header: 'News & Facts',
        slides: [
            {
                imageSrc: news1,
                title: ['Don’t miss Harvest', 'Month in Napa Valley!'],
                description: 'There’s no better time to visit Napa & Sonoma Valley than the fall harvest season when grapes are ripe, temperatures are comfortable and autumnal foliage is the most colorful. The region is abuzz with grape-picking activity for crush and winemaking. Don’t miss it!'
            },
            {
                imageSrc: news2,
                title: ['Get a certificate', 'For your friends'],
                description: 'Make your family, friends or colleagues jumping, screaming and laughing by offering them an unforgettable one, two of three day all-inclusive retreat vacation in marvelous Napa Valley. Birthdays, Anniversaries, Rewards... you find the occasion to make someone happy and we’ll take care of the rest.'
            }
        ]
    }
]

export default slidersData;