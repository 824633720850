import * as S from './style';

function Star() {
    return (
        <S.StarWrapper>
            <S.Star width="56" height="53" viewBox="0 0 56 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28 0L34.5109 20.0385L55.5806 20.0385L38.5349 32.423L45.0458 52.4615L28 40.077L10.9542 52.4615L17.4651 32.423L0.419361 20.0385L21.4891 20.0385L28 0Z" fill="url(#paint0_linear_591_318)"/>
<defs>
<linearGradient id="paint0_linear_591_318" x1="2.91396" y1="14.4578" x2="41.2615" y2="62.6948" gradientUnits="userSpaceOnUse">
<stop stop-color="#F8D778"/>
<stop offset="0.23" stop-color="#D6B677"/>
<stop offset="0.575" stop-color="#BD8D1C"/>
<stop offset="0.725" stop-color="#C5962A"/>
<stop offset="1" stop-color="#E2B85C"/>
</linearGradient>
</defs>
</S.Star>

        </S.StarWrapper>
    )
}

export default Star;