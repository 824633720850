import { forwardRef } from 'react';
import * as S from './style';

const GrapeIcon = forwardRef((props, ref) => {
    return (
        <S.GrapeIcon ref={ref} width="44" height="51" viewBox="0 0 44 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 12C17.3137 12 20 9.31371 20 6C20 2.68629 17.3137 0 14 0C10.6863 0 8 2.68629 8 6C8 9.31371 10.6863 12 14 12Z" fill="#FBB040" />
            <path d="M6 25C9.31371 25 12 22.3137 12 19C12 15.6863 9.31371 13 6 13C2.68629 13 0 15.6863 0 19C0 22.3137 2.68629 25 6 25Z" fill="#FBB040" />
            <path d="M14 38C17.3137 38 20 35.3137 20 32C20 28.6863 17.3137 26 14 26C10.6863 26 8 28.6863 8 32C8 35.3137 10.6863 38 14 38Z" fill="#FBB040" />
            <path d="M28 45C28 48.3137 25.3137 51 22 51C18.6863 51 16 48.3137 16 45C16 41.6863 18.6863 39 22 39C25.3137 39 28 41.6863 28 45Z" fill="#FBB040" />
            <path d="M36 6C36 9.31371 33.3137 12 30 12C26.6863 12 24 9.31371 24 6C24 2.68629 26.6863 0 30 0C33.3137 0 36 2.68629 36 6Z" fill="#FBB040" />
            <path d="M22 25C25.3137 25 28 22.3137 28 19C28 15.6863 25.3137 13 22 13C18.6863 13 16 15.6863 16 19C16 22.3137 18.6863 25 22 25Z" fill="#FBB040" />
            <path d="M36 32C36 35.3137 33.3137 38 30 38C26.6863 38 24 35.3137 24 32C24 28.6863 26.6863 26 30 26C33.3137 26 36 28.6863 36 32Z" fill="#FBB040" />
            <path d="M38 25C41.3137 25 44 22.3137 44 19C44 15.6863 41.3137 13 38 13C34.6863 13 32 15.6863 32 19C32 22.3137 34.6863 25 38 25Z" fill="#FBB040" />
        </S.GrapeIcon>
    )
})

export default GrapeIcon;